import Cookies from "js-cookie";

const TokenKey = 'shblog_token'
const RefreshTokenKey = 'shblog_refresh_token'

const UsernameKey = 'username'

function getTwoHoursExpiration() {
    const now = new Date();
    now.setTime(now.getTime() + (2 * 60 * 60 * 1000)); // 单位是毫秒
    return now;
}

// 在cookie中放置username
export function getUsername(){
    return Cookies.get(UsernameKey)
}

export function setUsername(name){
    return Cookies.set(UsernameKey, name, {expires: getTwoHoursExpiration()})
}

export function removeUsername(){
    return Cookies.remove(UsernameKey)
}

// 令牌
export function getToken(){
    return Cookies.get(TokenKey)
}

export function setToken(token){
    return Cookies.set(TokenKey, token, {expires: getTwoHoursExpiration()})
}

export function removeToken(){
    return Cookies.remove(TokenKey)
}

// 刷新令牌
export function getRefreshToken(){
    return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token){
    return Cookies.set(RefreshTokenKey, token, {expires: 14})
}

export function removeRefreshToken(){
    return Cookies.remove(RefreshTokenKey)
}